.question + .question {
  border-top: 1px solid var(--color-hr);
}

.question:nth-child(2n) {
  background-color: var(--color-zebra);
}

.question > div {
  padding: 16px;
}

.question img {
  max-width: 100%;
  max-height: 80vh;
  box-sizing: border-box;
  padding: 16px 0 0 32px;
}

.question ul {
  list-style-type: none;
  margin: 0 0 0 32px;
  padding: 0;
}

.text {
  display: flex;
}

.text > div {
  position: relative;
  top: 4px;
  color: var(--color-font2);
  font-size: 12px;
  flex: 0 0 32px;
  width: 32px;
}

.text > p {
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  text-align: justify;
  font-weight: 600;
}

.points {
  margin: 4px 0 0 32px;
  font-size: 12px;
  color: var(--color-font2);
}

.group img {
  max-width: 100%;
  max-height: 80vh;
  box-sizing: border-box;
  padding: 16px 0 0 32px;
}

.group ul {
  list-style-type: none;
  margin: 0 0 0 32px;
  padding: 0;
}

.answer {
  margin-top: 16px;
  line-height: 1.2;
  display: flex;
}

.answer div {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.answer button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-font2);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 16px;
  background-color: var(--color-main);
  flex-grow: 0;
  flex-shrink: 0;
  stroke: var(--color-font);
  fill: var(--color-font);
}

.answer button svg {
  font-size: 12px;
}

.answer button.check {
  stroke: var(--color-green);
  fill: var(--color-green);
  border-color: var(--color-green);
}

.answer button.cross {
  stroke: var(--color-red);
  fill: var(--color-red);
  border-color: var(--color-red);
}

@media all and (max-width: 640px) {
  .text {
    flex-direction: column;
  }
  .text > div {
    flex: 0;
    top: -4px;
  }
  .text > p {
    font-size: 16px;
  }
  .answer {
    font-size: 14px;
  }
  .question ul {
    margin: 0;
  }
  .question img {
    padding-left: 0;
  }
  .points {
    margin-left: 0;
  }
}

.results {
  padding: 16px;
  text-align: center;
  border-top: 1px solid var(--color-hr);
}

.results table {
  display: inline-block;
  margin-bottom: 16px;
}
.results th,
.results td {
  padding: 8px;
  color: inherit;
}

.results th:first-child,
.results td:first-child {
  text-align: left;
}

.results thead {
  background-color: var(--color-bg);
  color: var(--color-bg-text);
}
.results tbody tr:nth-child(2n + 1) {
  background-color: var(--color-zebra);
}

.red {
  color: var(--color-red);
}
.orange {
  color: var(--color-orange);
}
.green {
  color: var(--color-green);
}

@media all and (max-width: 640px) {
  .results {
    font-size: 14px;
  }
}

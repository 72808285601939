body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-main);
  color: var(--color-font);
}

body.light {
  --color-main: #fff;
  --color-zebra: rgba(0, 0, 0, 0.05);
  --color-font: rgba(0, 0, 0, 0.96);
  --color-font2: rgba(0, 0, 0, 0.5);
  --color-hr: #e5e5e5;
  --color-bg: #09244f;
  --color-bg-shadow: #09244f40;
  --color-bg-text: #fff;
  --color-red: #ff0000;
  --color-orange: #ffa500;
  --color-green: #008000;
  --color-footer: #eee;
}
body.dark {
  --color-main: #000010;
  --color-zebra: rgba(255, 255, 255, 0.05);
  --color-font: rgba(255, 255, 255, 0.9);
  --color-font2: rgba(255, 255, 255, 0.5);
  --color-hr: #111;
  --color-bg: #000033;
  --color-bg-shadow: #09244f40;
  --color-bg-text: #fff;
  --color-red: #ff0000ee;
  --color-orange: #ffa500ee;
  --color-green: #006000ee;
  --color-footer: #000;
}

body > svg {
  display: none;
}

header {
  top: 0;
  position: sticky;
  z-index: 1;
  background-color: var(--color-bg);
  color: var(--color-bg-text);
  box-shadow: 0px 4px 4px var(--color-bg-shadow);
  overflow: hidden;
}
header > div {
  display: flex;
  align-items: center;
  height: 60px;
}

header a,
header button {
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 0 12px;
  height: 100%;
  stroke: var(--color-bg-text);
  fill: var(--color-bg-text);
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

h1 {
  margin: 0;
  font-size: 24px;
  padding: 0 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

h2 {
  margin: 16px;
  font-size: 22px;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

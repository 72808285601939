.list {
  list-style-type: circle;
  padding: 0 16px;
  margin: 0 0 0 16px;
}
.list li {
  line-height: 2.25;
}
.list a {
  color: var(--color-font);
}

.footer {
  text-align: center;
  font-size: 12px;
  color: var(--color-font2);
  padding: 16px;
  margin-top: 32px;
  background-color: var(--color-footer);
}

@media all and (max-width: 640px) {
  .footer {
    font-size: 10px;
  }
}

.header h1 {
  padding-left: 0;
}

.header button {
  opacity: 0.5;
}
.header button.active {
  opacity: 1;
}
